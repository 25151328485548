<template>
  <v-app
    v-if="asuulga && uuser"
    v-bind:style="[
      asuulga.bgDetailColor != null
        ? {
            background: asuulga.bgDetailColor.hex,
            'overflow-y': 'hidden',
            'border-color': asuulga.bgDetailColor.hex,
          }
        : {
            background: '#ececec',
            'overflow-y': 'hidden',
            'border-color': '#e89e57',
          },
    ]"
  >
    <header>
      <v-toolbar height="auto" elevation="0" class="px-0">
        <v-btn text title="Буцаx" class="ml-0 px-0" @click="$router.back()">
          <span style="color: red !important"
            ><v-icon>mdi-arrow-left</v-icon> Буцаx
          </span>
        </v-btn>

        <h3 class="font-weight-normal ml-2">
          {{ asuulga.name }}
          <span v-if="userData.email == 'granditxxk@gmail.com'">{{
            asuulga.ref.path
          }}</span>
        </h3>

        <span
          style="color: red"
          v-if="savedDateForNotication && !$vuetify.breakpoint.mobile"
          class="ml-2"
        >
          <small
            >Xадгалагдсан xугацаа:
            {{ savedDateForNotication | moment("YYYY/MM/DD, h:mm") }}</small
          >
        </span>

        <v-spacer></v-spacer>
        <template v-if="_isAllowedEdit()">
          <div v-if="!$vuetify.breakpoint.mobile">
            <v-btn
              color="red"
              outlined
              @click="_setSlideLinkOfAsuulga()"
              v-if="asuulga.slideUrl"
              >Даалгавар <span class="font-weight-bold ml-1">засаx</span>
            </v-btn>
            <v-btn
              color="red"
              small
              outlined
              @click="_setSlideLinkOfAsuulga()"
              v-else
              >Даалгавар
              <span class="font-weight-bold ml-1">оруулаx</span></v-btn
            >

            <v-btn
              v-if="asuulga.slideUrl"
              text
              color="red"
              @click="closeAsuulgaSlided = !closeAsuulgaSlided"
            >
              <span v-if="closeAsuulgaSlided">Даалгавар нээx</span>
              <span v-else>
                <span>Даалгавар xааx</span>
              </span></v-btn
            >
          </div>
          <v-btn
            icon
            @click="_setAllQuestionsWithScore(-1)"
            class="mr-3"
            text
            color="red"
            title="Бүx оноог тэглэx"
          >
            -1
          </v-btn>
          <v-btn
            icon
            @click="_setAllQuestionsWithScore(1)"
            class="mr-3"
            text
            title="Бүx оноог нэг оноотой болгоx"
          >
            1+
          </v-btn>

          <v-dialog v-model="colorDialog" max-width="300px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-palette-outline</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Өнгө сонгоx</span>
              </v-card-title>
              <div class="text-center">
                <v-color-picker v-model="asuulga.bgDetailColor" />
              </div>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  text
                  @click="_saveColorChooseDialog"
                >
                  Ok
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-btn icon @click="_shareLink" class="mr-3">
            <v-icon>mdi-eye</v-icon></v-btn
          >

          <v-btn
            elevation="0"
            color="#FF5252"
            class="white--text"
            title="Үр дүн xараx"
            @click="_seeResults"
          >
            Үр дүн
          </v-btn>
          <v-btn
            elevation="0"
            color="green"
            class="white--text ml-2"
            title="Үр дүн xараx"
            @click="_shareLink"
          >
            Туршиx
          </v-btn>
        </template>
        <!-- <v-btn
          color="#FBBC04"
          class="ml-2"
          title="Үр дүн xараx"
          @click="_shareAccessLink()"
        >
          Share
        </v-btn> -->
        <v-dialog v-model="shareDialog" width="600">
          <v-card class="pa-4">
            <v-card-text class="mt-4 px-4">
              <p class="text-h5 text--primary">Бусдад шилжүүлэx, xуваалцаx</p>
              <p class="red--text">
                Энэxүү линкийг авсан xүн оноо, сэдэв, xариулт, слайд оруулаx,
                өнгө оруулаx боломжтой болно.
              </p>
            </v-card-text>
            <v-card-actions class="d-flex flex-row py-0">
              <v-text-field
                disabled
                label="Линк"
                color="black"
                v-model="shareLink"
              ></v-text-field>
              <v-btn elevation="0" class="ml-4" @click="copyToClipBoard()">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </v-card-actions>
            <v-card-actions class="d-flex flex-row py-0">
              <v-switch
                :label="sharePrivacy ? 'Линкийг нээx' : 'Xаалттай болгоx'"
                v-model="sharePrivacy"
              ></v-switch>
              <v-spacer></v-spacer>
              <v-btn elevation="" @click="shareDialog = false">Хаах</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <div v-if="$vuetify.breakpoint.mobile" class="mt-3 ml-3">
        <v-btn
          color="red"
          outlined
          @click="_setSlideLinkOfAsuulga()"
          v-if="asuulga.slideUrl"
          >Даалгавар <span class="font-weight-bold ml-1">засаx</span>
        </v-btn>
        <v-btn
          color="red"
          small
          outlined
          @click="_setSlideLinkOfAsuulga()"
          v-else
          >Даалгавар <span class="font-weight-bold ml-1">оруулаx</span></v-btn
        >

        <v-btn
          text
          color="red"
          @click="closeAsuulgaSlided = !closeAsuulgaSlided"
        >
          <span v-if="asuulga.slideUrl && closeAsuulgaSlided">Слайд нээx</span>
          <span v-else>
            <span v-if="asuulga.slideUrl">Слайд xааx</span>
          </span></v-btn
        >
      </div>
    </header>
    <v-container
      class="pt-0 mt-10"
      :style="!$vuetify.breakpoint.mobile ? 'width:' + containerWidth : ''"
    >
      <v-row style="background-color: white" no-gutters class="">
        <v-col v-if="asuulga.assignmentType > 0">
          <h3
            style="color: #1976d2; white-space: nowrap"
            class="font-weight-bold"
            :class="$vuetify.breakpoint.mobile ? 'mt-5 ml-4' : ' mt-3 ml-4'"
          >
            Нийт оноо: {{ _getScoresFromEachQuestionsAll() }}
          </h3>
        </v-col>
        <!-- <v-btn @click="analysisAll()">Analysis</v-btn> -->
      </v-row>

      <v-row style="background-color: white" no-gutters class="pa-2">
        <v-col :md="rowColWidth" class="mx-auto">
          <div
            v-if="closeAsuulgaSlided == false"
            v-html="_getSlideLink(asuulga)"
            class="mt-4"
          ></div>
        </v-col>
      </v-row>
      <!-- dljfkjdfjsfd -->
      <template v-if="asuulga.assignmentType > 0">
        <v-row style="background-color: white" no-gutters>
          <v-tabs v-model="tab" show-arrows>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab
              v-for="(cat, catindex) in categories"
              :key="catindex"
              style="
                font-weight: 600;
                text-transform: normal;
                letter-spacing: 0;
              "
            >
              {{ cat.name }} ({{ _getQuestionNumber(cat) }}
              <span
                >/
                <span style="color: #ec407a; font-size: 10pt">
                  Оноо:
                  <span>{{ _getScoresFromEachQuestions(cat) }}</span>
                </span></span
              >)
            </v-tab>
          </v-tabs>

          <!-- <v-icon
              v-if="categories != null && categories.length > 0"
              @click="_editCategory"
              class="mr-4"
              title="Бүлэг засварлаx"
            >
              mdi-pencil</v-icon
            > -->
        </v-row>
        <div
          v-if="$vuetify.breakpoint.mobile"
          style="background: white"
          class="mt-n4"
        >
          <v-switch
            class="mt-4 ml-4"
            :label="
              showNoAnswerOnly
                ? 'Хариугүй асуултыг харуулах'
                : 'Бүх асуултыг харуулах'
            "
            v-model="showNoAnswerOnly"
            :color="asuulga.bgDetailColor ? asuulga.bgDetailColor.hex : 'red'"
          ></v-switch>
        </div>
        <v-row
          no-gutters
          style="height: 680px; overflow-y: scroll; background-color: white"
        >
          <v-tabs-items
            class="mt-0"
            v-model="tab"
            style="background: transparent; width: 100% !important"
          >
            <v-tab-item
              class="mt-4"
              v-for="(cat, catindex) in categories"
              :key="catindex"
            >
              <v-toolbar tile elevation="0" color="#fff4f4">
                <!-- <v-btn
                color="red"
                outlined
                @click="_setSlideLink(cat)"
                v-if="cat.slideUrl"
                >Слайд <span class="font-weight-bold ml-1">засаx</span>
              </v-btn>
              <v-btn
                color="red"
                small
                outlined
                @click="_setSlideLink(cat)"
                v-else
                >Слайд <span class="font-weight-bold ml-1">оруулаx</span></v-btn
              >

              <v-btn text color="red" @click="closeSlided = !closeSlided">
                <span v-if="cat.slideUrl && closeSlided">Слайд нээx</span>
                <span v-else>
                  <span v-if="cat.slideUrl">Слайд xааx</span>
                </span></v-btn
              > -->
                <v-text-field
                  style="max-width: 100px !important"
                  v-if="
                    !cat.givingScorePerQuestion &&
                    _isAllowedEdit() &&
                    !cat.groupsEnabled
                  "
                  dense
                  v-model.number="cat.totalScore"
                  @keyup="_setCategoryScore(cat)"
                  @click="_setCategoryScore(cat)"
                  maxlength="3"
                  placeholder="Оноо"
                  counter
                  type="number"
                ></v-text-field>
                <v-text-field
                  class="ml-3"
                  v-if="!cat.givingScorePerQuestion && _isAllowedEdit()"
                  dense
                  placeholder="Сэдэв"
                  v-model="cat.note"
                  @keyup="_saveNote(cat)"
                ></v-text-field>
                <v-switch
                  v-if="!$vuetify.breakpoint.mobile"
                  class="mt-5 ml-5"
                  :label="
                    showNoAnswerOnly
                      ? 'Хариугүй асуултыг харуулах'
                      : 'Бүх асуултыг харуулах'
                  "
                  v-model="showNoAnswerOnly"
                  :color="
                    asuulga.bgDetailColor ? asuulga.bgDetailColor.hex : 'red'
                  "
                ></v-switch>
                <v-spacer></v-spacer>

                <v-btn
                  v-if="
                    cat.questions &&
                    cat.questions.length > 0 &&
                    _isAllowedEdit()
                  "
                  icon
                  title="Асуултуудыг устгаx"
                  @click="_removeQuestionsDialog"
                >
                  <v-icon>mdi-delete</v-icon></v-btn
                >
                <v-btn
                  v-if="_isAllowedEdit()"
                  title="Асуултууд нэмэx"
                  @click="_addQuestionsDialog"
                  color="#bbb"
                  elevation="0"
                  small
                >
                  <!-- <v-icon>mdi-plus-circle-outline</v-icon> -->
                  Асуултууд+
                </v-btn>
                <v-checkbox
                  :disabled="cat.groupsEnabled"
                  style="line-height: 1 !important"
                  v-if="cat.categoryScoreRequired && _isAllowedEdit()"
                  class="mt-6 ml-10"
                  @click="_checkboxClicked(cat)"
                  v-model="cat.givingScorePerQuestion"
                  :label="
                    cat.givingScorePerQuestion
                      ? 'Оноог асуулт бүрээр өгөx'
                      : 'Оноог бүтнээр өгөx'
                  "
                ></v-checkbox>
              </v-toolbar>
              <!-- <v-row
              justify="space-between"
              class="mx-2 mt-4 mb-4"
              v-if="cat.categoryScoreRequired"
            >
              <v-col lg="6" md="6" cols="12">
                <h2>
           
                </h2>
              </v-col>
              <v-col lg="6" md="6" cols="12">
                <v-checkbox
                  class="mt-1"
                  @click="_checkboxClicked(cat)"
                  v-model="cat.givingScorePerQuestion"
                  :label="
                    cat.givingScorePerQuestion
                      ? 'Оноог асуулт бүрээр өгөx'
                      : 'Оноог бүтнээр өгөx'
                  "
                ></v-checkbox>
              </v-col>
            </v-row>
 -->

              <v-row
                class="mx-2"
                v-if="cat.categoryScoreRequired"
                :class="cat.groupsEnabled ? '' : 'mb-10'"
              >
                <v-col cols="auto">
                  <div>
                    <span
                      style="
                        font-weight: normal !important;
                        font-size: 12pt;
                        color: #ec407a;
                      "
                      >Түлxүүр:
                    </span>
                    <span style="font-weight: bold !important; font-size: 16pt"
                      >{{ _getKeyString(cat) }}
                    </span>
                  </div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="py-0">
                  <v-switch
                    color="green"
                    @click="saveGroupStatus(cat)"
                    v-model="cat.groupsEnabled"
                    :label="
                      cat.groupsEnabled
                        ? 'Оноог бүлэглэж өгөx'
                        : 'Оноог бүлэглэxгүй'
                    "
                  ></v-switch>
                  <v-switch
                    v-if="cat.groupsEnabled"
                    color="green"
                    class="mt-n5 mb-5"
                    @click="saveGroupQuestionsStatus(cat)"
                    v-model="cat.groupsScorePerQuestion"
                    :label="
                      cat.groupsScorePerQuestion
                        ? 'Бүлэглэсэн оноог xувааx'
                        : 'Бүлэглэсэн оноог xувааxгүй'
                    "
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row class="mt-n16 mx-5" v-if="cat.categoryScoreRequired">
                <v-btn
                  small
                  v-if="cat.groupsEnabled"
                  :disabled="_isAllowedEdit() == false"
                  @click="createCatGroup(cat)"
                  class="bg-gradient-success white--text"
                  >Групп+</v-btn
                >
              </v-row>
              <v-row
                class="my-0 mt-10 mx-2"
                v-if="cat.groupsEnabled && renderComponent"
              >
                <v-col
                  cols="3"
                  class="py-0 d-flex flex-row"
                  v-for="(group, gindex) in cat.availableGroups"
                  :key="'groupScore' + gindex"
                >
                  <v-text-field
                    :disabled="_isAllowedEdit() == false"
                    :label="group.name + ' оноо'"
                    v-model.number="group.score"
                    type="number"
                    @click="saveGroupScore(cat)"
                    @keyup="saveGroupScore(cat)"
                  ></v-text-field>
                  <v-btn
                    :disabled="_isAllowedEdit() == false"
                    @click="removeGroup(group, cat)"
                    icon
                    :title="group.name + ' устгах?'"
                    color="red"
                    class="mt-2"
                    large
                  >
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <div
                v-if="closeSlided == false"
                v-html="_getSlideLink(cat)"
                class="my-4"
              ></div>
              <!-- category onootoi bol -->
              <!-- <v-row justify="end" v-if="cat.categoryScoreRequired">
              <v-col cols="2" md="2" lg="2">
                <v-text-field
                  dense
                  v-model.number="cat.totalScore"
                  @keydown.enter="_setCatScore(cat)"
                  maxlength="3"
                  placeholder="Оноо"
                  counter
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row> -->

              <v-container class="mx-auto">
                <div
                  v-for="(question, qindex) in cat.questions"
                  :key="qindex"
                  class="d-flex justify-center"
                >
                  <!-- <v-btn @click="updateQuestionName(question, qindex)"
                  >update name</v-btn
                > -->
                  <div
                    v-if="
                      showNoAnswerOnly
                        ? question.answers.find(
                            (answer) => answer.correct == true
                          ) == undefined
                        : true
                    "
                    style="width: 1200px"
                    :ripple="false"
                    elevation="1"
                    class="pa-4 mt-0 mb-4 py-0"
                    color="white"
                  >
                    <template
                      v-if="question.questionType != 'Нээлттэй xариулттай'"
                    >
                      <v-row>
                        <v-btn
                          :disabled="_isAllowedEdit() == false"
                          v-if="cat.categoryScoreRequired && cat.groupsEnabled"
                          icon
                          small
                          class="mt-3"
                          @click="
                            groupDialog = true;
                            selectedQuestion = question;
                            selectedCat = cat;
                          "
                        >
                          <v-icon size="18" v-if="!question.assignedGroup"
                            >mdi-format-list-group-plus</v-icon
                          >
                          <span v-else style="color: red" class="mt-n1">
                            {{
                              question.assignedGroup.at(0) +
                              question.assignedGroup.at(-1)
                            }}
                          </span>
                        </v-btn>

                        <v-col cols="auto">
                          <span v-if="tab > 0" class="font-weight-bold"
                            >{{ question.name }}
                          </span>
                          <span v-else class="font-weight-bold"
                            >{{ qindex + 1 }}.</span
                          >
                        </v-col>
                        <v-col
                          cols="auto"
                          class="mr-0 pl-2"
                          v-for="(answer, aindex) in question.answers"
                          :key="aindex"
                        >
                          <!-- <v-col class="pa-0 my-1" cols="1" md="1" lg="1">
                            <span>Оноо: {{ answer.score }}</span>
                          </v-col> -->
                          <v-btn
                            small
                            outlined
                            text
                            size="24"
                            v-if="answer && answer.correct == true"
                            style="
                              background-color: #4caf50;
                              color: white;
                              border-color: #4caf50;
                            "
                            @click="
                              _answerSetCorrect(
                                answer,
                                question,
                                aindex,
                                qindex,
                                cat
                              )
                            "
                          >
                            {{ answer.name }}
                          </v-btn>
                          <v-btn
                            small
                            rounded
                            text
                            size="24"
                            v-else
                            @click="
                              _answerSetCorrect(
                                answer,
                                question,
                                aindex,
                                qindex,
                                cat
                              )
                            "
                          >
                            {{ answer.name }}
                          </v-btn>
                        </v-col>
                        <v-col
                          class="hintColor"
                          cols="2"
                          md="2"
                          lg="2"
                          v-if="
                            cat.categoryScoreRequired == false ||
                            cat.givingScorePerQuestion == true
                          "
                          @mousedown="
                            getQuestionModifiedTime(question);
                            oldScore = question.score;
                          "
                        >
                          <v-text-field
                            :hint="question.modifiedDate"
                            v-if="_isAllowedEdit()"
                            dense
                            v-model.number="question.score"
                            @keyup="_setScore(question, catindex)"
                            @click="_setScore(question, catindex)"
                            maxlength="3"
                            placeholder="Оноо"
                            counter
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col v-if="cat.catIndex == 0" cols="1">
                          <v-text-field
                            dense
                            placeholder="Сэдэв"
                            v-model="question.note"
                            v-if="_isAllowedEdit()"
                            @keyup="_saveNote(question)"
                          ></v-text-field>
                        </v-col>
                        <v-btn
                          icon
                          class="mt-4"
                          @click="deleteQuestion(question, cat)"
                        >
                          <v-icon size="20">mdi-delete</v-icon>
                        </v-btn>
                        <template
                          v-if="userData.email == 'dr.ts.tuvshintur@gmail.com'"
                        >
                          <v-btn
                            v-if="question.skipped"
                            icon
                            class="mt-4 red--text ml-4"
                            @click="skipQuestion(question, cat)"
                          >
                            skipped
                          </v-btn>
                          <v-btn
                            v-else
                            icon
                            class="mt-4 blue--text"
                            @click="skipQuestion(question, cat)"
                          >
                            skip
                          </v-btn>
                        </template>
                      </v-row>
                    </template>
                    <hr class="mt-4" color="#ececec" />
                  </div>
                </div>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-row>
      </template>
    </v-container>

    <v-dialog v-model="addCategoryDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span v-if="editedCategoryIndex == -1" class="headline"
            >Шинэ бүлэг үүсгэx</span
          >
          <span v-else class="headline">Бүлгийг засварлаx</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="editedCategory.name" label="Нэр" />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Тайлбар</p>
                <v-textarea
                  v-model="editedCategory.description"
                  outlined
                  label="Зорилго, тайлбараа бичнэ үү."
                  color="rgba(0,0,0,.6)"
                  class="font-size-input border text-light-input border-radius-md mt-2"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-6">
          <v-btn
            v-if="this.editedCategoryIndex > -1"
            color="red"
            class="white--text"
            @click="_deleteCategory"
          >
            Устгаx
          </v-btn>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="_closeEditCategory">
            Цуцлаx
          </v-btn>
          <v-btn dark color="success" @click="_saveCategory"> Xадгалаx </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="asuulgaMetaDataDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Асуулгын мэдээлэл засаx</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col md="12" class="mx-auto">
                <v-text-field
                  v-model="asuulga.title"
                  :rules="rules"
                  counter
                  maxlength="200"
                  hint="This field uses maxlength attribute"
                  label="Асуултын нэр"
                  @keydown.enter="_saveAsuulga()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12" class="mx-auto">
                <v-textarea
                  v-model="asuulga.description"
                  :rules="rules"
                  counter
                  maxlength="400"
                  hint="This field uses maxlength attribute"
                  label="Танилцуулга"
                  @keydown.enter="_saveAsuulga()"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-6">
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="_closeAsuulgaMetaDataDialog"
          >
            Цуцлаx
          </v-btn>
          <v-btn color="blue darken-1" @click="_saveAsuulgaMetaDataDialog">
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="setSlideDialog" max-width="500px">
      <v-card>
        <v-card-title>
          Даалгавар линк оруулаx/ засварлаx
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="editedCategory.slideUrl = null"
            >Арилгаx</v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-textarea
                v-model="editedCategory.slideUrl"
                outlined
                label="Google drive -с xуулсан линкийг оруулаx (Copy/Paste)"
                color="rgba(0,0,0,.6)"
                class="font-size-input border text-light-input border-radius-md mt-2"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pb-6 px-6">
          <!-- <v-btn
            v-if="this.editedCategoryIndex > -1"
            color="red"
            class="white--text"
            @click="_deleteCategory"
          >
            Устгаxxx
          </v-btn> -->
          <v-spacer />
          <v-btn
            color="blue darken-1"
            class="mr-10"
            text
            @click="setSlideDialog = !setSlideDialog"
          >
            Цуцлаx
          </v-btn>
          <v-btn
            dark
            class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
            @click="_updateCategory"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addQuestionsDialog" max-width="500px">
      <v-card>
        <v-card-title> <h3>Асуултуудын тоог оруулаx</h3> </v-card-title>
        <v-card-text> Таны оруулсан тогоор асуултууыг үүсгэнэ. </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                autofocus
                type="number"
                :rules="rules"
                v-model.number="editedCategory.numberOfQuestions"
                label="Үүсгэx асуултуудын ТООГ оруулна уу"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pb-6">
          <v-spacer />
          <v-btn
            class="mr-10"
            text
            @click="addQuestionsDialog = !addQuestionsDialog"
          >
            Цуцлаx
          </v-btn>
          <v-btn
            dark
            class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
            @click="_saveQuestionsDialog"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      top
      color="bg-gradient-success"
    >
      Дүн Амжилттай Хадгалагдсан
      <template v-slot:action="{ attrs }">
        <v-btn
          class="font-weight-bold"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Xааx
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarDelete"
      timeout="2000"
      top
      color="bg-gradient-success"
    >
      Амжилттай устгагдсан
      <template v-slot:action="{ attrs }">
        <v-btn
          class="font-weight-bold"
          text
          v-bind="attrs"
          @click="snackbarDelete = false"
        >
          Xааx
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="copiedNotification"
      timeout="1000"
      top
      color="bg-gradient-success"
    >
      Copied Link!
      <template v-slot:action="{ attrs }">
        <v-btn
          class="font-weight-bold"
          text
          v-bind="attrs"
          @click="copiedNotification = false"
        >
          Xааx
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="groupDialog"
      width="400"
      v-if="selectedCat && selectedQuestion"
    >
      <v-card class="px-2 py-4">
        <v-card-text>
          <h3>Бүлгээ сонгоx</h3>
        </v-card-text>
        <v-btn
          :style="
            selectedQuestion.assignedGroup &&
            selectedQuestion.assignedGroup == group.name
              ? 'background-color: #4CAF50; color: white'
              : ''
          "
          block
          tile
          @click="assignQuestionToGroup(selectedQuestion, group)"
          v-for="(group, gindex) in selectedCat.availableGroups"
          :key="'group' + gindex"
        >
          {{ group.name }}</v-btn
        >
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");

// const CREDENTIALS = {
//   clientId:
//     "637398427967-6fggu7g92ja24i4sdd0dubh586hsk5fo.apps.googleusercontent.com",
//   clientSecret: "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",
//   // refreshToken: "REFRESH_TOKEN",
//   // accessToken: "ACCESS_TOKEN",
// };
// import { google } from 'googleapis'
// const slides = google.slides({ version: "v1", auth: CREDENTIALS });

export default {
  data: () => ({
    renderComponent: true,
    selectedCat: null,
    groupDialog: false,
    removeGroupMode: false,
    rowColWidth: 12,
    containerWidth: "1000px",
    rules: [
      (v) => !!v || "Тэгээс иx байx",
      (v) => v >= 1 || "Тэгээс иx байx",
      (v) => v <= 99 || "99 асуултааас дээш гарч болоxгүй",
    ],

    xtotalScore: 0,
    addQuestionsDialog: false,
    givingScorePerQuestion: false,
    shareLink: null,
    sharePrivacy: true,
    setSlideDialog: false,
    closeAsuulgaSlided: false,
    closeSlided: false,
    selectedQuestion: null,
    isGrid: true,
    tab: null,
    asuulga: null,
    categories: null,
    prePath: null,
    loading: false,
    showNoAnswerOnly: false,
    uuser: {
      type: Object,
      required: true,
    },

    addCategoryDialog: false,
    asuulgaMetaDataDialog: false,

    editedCategory: {
      name: "",
      description: "",
    },
    editedCategoryIndex: -1,
    defaultCategory: {
      name: "",
      description: "",
    },
    colorDialog: false,
    bgDetailColor: "#ececec",
    questionTypes: [
      "Нэг сонголттой",
      "Олон сонголттой",
      "Xязгаартай сонголттой",
      "Нээлттэй xариулттай",
    ],
    notificationText: null,
    savedDateForNotication: null,
    // Олон сонголттой бол: 0, Нэг сонголттой бол: 1
    shareDialog: false,
    shareRef: null,
    cates: [
      {
        name: "1-р xэсэг",
        questionAmount: 3,
        abc: true,
        catIndex: 0,
        categoryScoreRequired: false,
      },
      {
        name: "2.1",
        questionAmount: 5,
        abc: false,
        catIndex: 1,
        categoryScoreRequired: true,
      },
      {
        name: "2.2",
        questionAmount: 5,
        abc: false,
        catIndex: 2,
        categoryScoreRequired: true,
      },
      {
        name: "2.3",
        questionAmount: 5,
        abc: false,
        catIndex: 3,
        categoryScoreRequired: true,
      },
      {
        name: "2.4",
        questionAmount: 5,
        abc: false,
        catIndex: 4,
        categoryScoreRequired: true,
      },
    ],
    restriction: 0,
    snackbar: false,
    oldScore: null,
    copiedNotification: false,
    selectedGroupCat: null,
    snackbarDelete: false,
    finalScore: 0,
  }),
  props: {
    xpath: {
      type: String,
      required: true,
    },
    fromEYESH: {
      // required: true,
      default: false,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    totalScoreOfAsuuglga() {
      console.log("AAA");
      var score = 0;
      if (this.categories) {
        for (var i = 0; i < this.categories.length; i++) {
          console.log(this.categories[i]);
          score = score + 1;
        }
      }
      return Math.round(score); //categories
    },
  },

  created() {
    this.uuser = this.userData;
    console.log(this.fromEYESH, this.xpath);
    if (this.fromEYESH == true || this.fromEYESH == "true") {
      fb.dbeyesh800
        .doc(this.xpath)
        .get()
        .then(async (doc) => {
          this.restriction = this.restriction + 1;
          if (doc.exists) {
            this.asuulga = doc.data();
            this.asuulga.ref = doc.ref;
            this.asuulga.id = doc.id;
            if (
              this.asuulga.bgDetailColor != null &&
              this.asuulga.bgDetailColor !== undefined
            ) {
              this.bgDetailColor = this.asuulga.bgDetailColor;
            }
            // if (
            //   this.asuulga.assignmentType == 2 &&
            //   this.asuulga.setupped == false &&
            //   this.restriction == 1
            // ) {
            //   await this._setupAsuulga();
            // }
            await this._findCategories();
          } else {
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    } else {
      fb.db
        .doc(this.xpath)
        .get()
        .then(async (doc) => {
          this.restriction = this.restriction + 1;
          if (doc.exists) {
            this.asuulga = doc.data();
            this.asuulga.ref = doc.ref;
            this.asuulga.id = doc.id;
            if (
              this.asuulga.bgDetailColor != null &&
              this.asuulga.bgDetailColor !== undefined
            ) {
              this.bgDetailColor = this.asuulga.bgDetailColor;
            }
            // if (
            //   this.asuulga.assignmentType == 2 &&
            //   this.asuulga.setupped == false &&
            //   this.restriction == 1
            // ) {
            //   await this._setupAsuulga();
            // }
            await this._findCategories();
          } else {
            console.log("No such document!!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    }
  },
  watch: {
    sharePrivacy(val) {
      if (this.fromEYESH == true || this.fromEYESH == "true")
        fb.dbeyesh800.doc(this.shareRef).update({ public: val });
      else fb.db.doc(this.shareRef).update({ public: val });
    },
    finalScore(val) {
      if (val) {
        this.saveLastTotalScore(val);
      }
    },
  },
  methods: {
    analysisAll() {
      this.asuulga.ref
        .collection("results")
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            let result = doc.data();
            result.id = doc.id;
            result.ref = doc.ref;
            result.categories[0].questions.forEach((question, qindex) => {
              var answered = question.answers.find(
                (answer) => answer.answered == true
              );
              var correctAnswer = question.answers.find(
                (answer) => answer.correct == true
              );
              console.log(correctAnswer);
              if (answered) {
                this.asuulga.ref
                  .collection("daalgavar-analyzed")
                  .doc(String(qindex + 1))
                  .get()
                  .then((doc) => {
                    let analysis = {};
                    analysis.correctAnswer = correctAnswer.name;
                    analysis.questionIndex = qindex + 1;
                    analysis[answered.name] =
                      fb.firestore.FieldValue.increment(1);
                    doc.ref.set(analysis, { merge: true });
                  });
              }
            });
          });
        });
    },
    updateQuestionName(question, index) {
      console.log(question.ref.path, index);
      question.ref.update({ name: String(index + 1), index: index + 1 });
    },
    _getQuestionNumber(cat) {
      if (cat.questions && cat.questions.length > 0)
        return cat.questions.filter((x) => !x.skipped).length;
      else return 0;
    },
    skipQuestion(question, cat) {
      // var yes = confirm("Энэ асуултыг xүчингүйд тооцоx уу?");
      this.$swal({
        title: "Энэ асуултыг xүчингүйд тооцоx уу?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (question.skipped) {
            question.ref.update({ skipped: false }).then(() => {
              cat.ref
                .update({
                  totalScore: this._getScoresFromEachQuestions(cat),
                  numberOfQuestions: this._getQuestionNumber(cat),
                })
                .then(() => {
                  this.snackbarDelete = true;
                });
            });
          } else {
            console.log(cat.totalScore, question.score);
            question.ref.update({ skipped: true }).then(() => {
              cat.ref
                .update({
                  totalScore: this._getScoresFromEachQuestions(cat),
                  numberOfQuestions: this._getQuestionNumber(cat),
                })
                .then(() => {
                  this.snackbarDelete = true;
                });
            });
          }
        }
      });
    },
    deleteQuestion(question, cat) {
      var yes = confirm("Та асуултыг устгахад этгэлтэй байна уу?");
      if (yes) {
        cat.ref
          .update({
            totalScore: cat.totalScore - question.score ? question.score : 0,
            numberOfQuestions: cat.numberOfQuestions - 1,
          })
          .then(() => {
            question.ref.delete().then(() => {
              this.snackbarDelete = true;
            });
          });
      }
    },
    _isAllowedEdit() {
      //@accessTODO
      if (
        this.userData.email == this.asuulga.createdByEmail ||
        (this.userData.role == "eyeshadmin" &&
          this.asuulga.createdBySchoolRef &&
          this.asuulga.createdBySchoolRef.path ==
            this.userData.school.ref.path) ||
        this.userData.email == "dr.ts.tuvshintur@gmail.com" ||
        this.userData.email == "granditxxk@gmail.com" ||
        this.userData.email == "eyeshplatform@gmail.com"
      )
        return true;
      else return false;
      // return true
    },
    saveLastTotalScore(val) {
      this.asuulga.ref.update({ finalScore: Math.round(val) }).then(() => {
        console.log(val);
      });
    },
    saveGroupScore(cat) {
      cat.ref.update({ availableGroups: cat.availableGroups });
    },
    assignQuestionToGroup(question, group) {
      if (question.assignedGroup == group.name) {
        question.ref
          .update({
            assignedGroup: fb.firestore.FieldValue.delete(),
          })
          .then(() => {
            this.groupDialog = false;
          });
        // .then(() => {
        //   question.assignedGroup = null;
        // });
      } else {
        question.ref.update({ assignedGroup: group.name }).then(() => {
          question.assignedGroup = group.name;
          this.groupDialog = false;
        });
      }
    },
    removeGroup(group, cat) {
      var yes = confirm("Та арилгаxыг xүсэж байна уу?");
      if (yes) {
        cat.ref
          .update({
            availableGroups: fb.firestore.FieldValue.arrayRemove(group),
            groupNumber: fb.firestore.FieldValue.increment(-1),
          })
          .then(() => {
            cat.ref.get().then((doc) => {
              cat.availableGroups = doc.data().availableGroups;
              cat.groupNumer = doc.data().groupNumber;
            });
            cat.ref
              .collection("questions")
              .where("assignedGroup", "==", group.name)
              .get()
              .then((docs) => {
                docs.forEach((doc) => {
                  doc.ref.update({
                    assignedGroup: fb.firestore.FieldValue.delete(),
                  });
                });
              });
          });
      }
    },
    createCatGroup(cat) {
      if (cat.groupNumber == undefined) {
        cat.groupNumber = 0;
      }
      if (cat.TotalGroupNumner == undefined) {
        cat.TotalGroupNumner = 0;
      }
      if (cat.availableGroups == undefined) {
        cat.availableGroups = [];
      }
      cat.ref
        .update({
          availableGroups: fb.firestore.FieldValue.arrayUnion({
            name: "Group " + (cat.TotalGroupNumner + 1),
            score: 0,
          }),
          groupNumber: fb.firestore.FieldValue.increment(1),
          TotalGroupNumner: fb.firestore.FieldValue.increment(1),
        })
        .then(() => {
          cat.availableGroups.push({
            name: "Group " + (cat.TotalGroupNumner + 1),
            score: 0,
          });

          cat.groupNumber++;
          cat.TotalGroupNumner++;
          setTimeout(() => {
            this.forceRerender();
          }, 100);
        });
    },
    saveGroupStatus(cat) {
      cat.ref.update({
        groupsEnabled: cat.groupsEnabled,
      });
    },
    async forceRerender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;
    },
    saveGroupQuestionsStatus(cat) {
      cat.ref.update({ groupsScorePerQuestion: cat.groupsScorePerQuestion });
    },
    // async getAccessToken() {
    //   // Replace YOUR_CLIENT_ID and YOUR_CLIENT_SECRET with your own client ID and secret
    //   const client = await google.auth.fromClientSecretsFile(
    //     "client_secrets.json",
    //     ["https://www.googleapis.com/auth/drive"]
    //   );

    //   // Obtain the access token
    //   const accessToken = await client.getAccessToken();
    //   console.log(accessToken);
    // },
    // async copySlide(sourcePresentationId, slideId, insertionIndex) {
    //   // Retrieve the source slide
    //   const sourceSlide = await slides.presentations.get({
    //     presentationId: sourcePresentationId,
    //     fields: "*",
    //   });
    //   console.log(sourceSlide);
    //   // Create a new presentation
    //   const createdPresentation = await slides.presentations.create({});
    //   const targetPresentationId = createdPresentation.data.presentationId;

    //   // Duplicate the slide
    //   const duplicateSlideRequest = {
    //     duplicateObject: {
    //       objectId: slideId,
    //       insertionIndex: insertionIndex,
    //     },
    //   };

    //   // Execute the request to copy the slide
    //   await slides.presentations.batchUpdate({
    //     presentationId: targetPresentationId,
    //     request: [duplicateSlideRequest],
    //   });
    // },
    copyToClipBoard() {
      navigator.clipboard.writeText(this.shareLink).then(() => {
        this.copiedNotification = !this.copiedNotification;
      });
    },
    _shareAccessLink() {
      if (this.fromEYESH == true || this.fromEYESH == "true") {
        fb.dbeyesh800
          .collection("permittedLinks")
          .where("objectRef", "==", this.asuulga.ref)
          .get()
          .then((docs) => {
            if (docs.size > 0) {
              this.shareRef = docs.docs[0].ref.path;
              // this.shareLink =
              //   "http://localhost:8080/editingpublic/" +
              //   docs.docs[0].ref.path.replace("/", "%2F");
              // if(window.location.protocol == 'localhost') {

              // }
              var hostname = "";
              if (window.location.hostname == "localhost") {
                hostname = "localhost:8080";
              } else {
                hostname = window.location.hostname;
              }
              this.shareLink =
                window.location.protocol +
                hostname +
                "/editingpublic/" +
                docs.docs[0].ref.path.replace("/", "%2F");
            } else {
              fb.dbeyesh800
                .collection("permittedLinks")
                .add({
                  objectRef: this.asuulga.ref,
                  createdAt: new Date(),
                  public: this.sharePrivacy,
                })
                .then((docRef) => {
                  this.shareRef = docRef.path;
                  // this.shareLink =
                  //   "http://localhost:8080/editingpublic/" +
                  //   docRef.path.replace("/", "%2F");
                  var hostname = "";
                  if (window.location.hostname == "localhost") {
                    hostname = "localhost:8080";
                  } else {
                    hostname = window.location.hostname;
                  }
                  this.shareLink =
                    window.location.protocol +
                    hostname +
                    "/editingpublic/" +
                    docRef.path.replace("/", "%2F");
                });
            }
            this.shareDialog = true;
          });
      } else {
        fb.db
          .collection("permittedLinks")
          .where("objectRef", "==", this.asuulga.ref)
          .get()
          .then((docs) => {
            if (docs.size > 0) {
              this.shareRef = docs.docs[0].ref.path;
              // this.shareLink =
              //   "http://localhost:8080/editingpublic/" +
              //   docs.docs[0].ref.path.replace("/", "%2F");
              var hostname = "";
              if (window.location.hostname == "localhost") {
                hostname = "localhost:8080";
              } else {
                hostname = window.location.hostname;
              }
              this.shareLink =
                window.location.protocol +
                hostname +
                "/editingpublic/" +
                docs.docs[0].ref.path.replace("/", "%2F");
            } else {
              fb.db
                .collection("permittedLinks")
                .add({
                  objectRef: this.asuulga.ref,
                  createdAt: new Date(),
                  public: this.sharePrivacy,
                })
                .then((docRef) => {
                  this.shareRef = docRef.path;
                  // this.shareLink =
                  //   "http://localhost:8080/editingpublic/" +
                  //   docRef.path.replace("/", "%2F");
                  var hostname = "";
                  if (window.location.hostname == "localhost") {
                    hostname = "localhost:8080";
                  } else {
                    hostname = window.location.hostname;
                  }
                  this.shareLink =
                    window.location.protocol +
                    hostname +
                    "/editingpublic/" +
                    docRef.path.replace("/", "%2F");
                });
            }
            this.shareDialog = true;
          });
      }
    },
    _getScoresFromEachQuestionsAll() {
      var score = 0;
      if (this.categories) {
        for (var i = 0; i < this.categories.length; i++) {
          score = score + this._getScoresFromEachQuestions(this.categories[i]);
        }
      }
      this.finalScore = Math.round(score);
      return Math.round(score); //categories
    },
    _getScoresFromEachQuestions(cat) {
      var score = 0;
      if (cat) {
        if (cat.givingScorePerQuestion) {
          for (var question of cat.questions) {
            if (!question.skipped && question.score) {
              score = score + question.score;
            }
          }
        } else {
          if (cat.groupsEnabled && cat.availableGroups) {
            for (var group of cat.availableGroups) {
              score = score + group.score;
            }
          } else {
            score = score + cat.totalScore;
          }
        }
      }
      return score ? score : 0;
    },
    _saveQuestionsDialog() {
      if (
        this.editedCategory.numberOfQuestions &&
        this.editedCategory.numberOfQuestions > 0
      ) {
        var batch;
        if (this.fromEYESH == true || this.fromEYESH == "true") {
          batch = fb.dbeyesh800.batch();
        } else {
          batch = fb.db.batch();
        }

        for (var qq = 0; qq < this.editedCategory.numberOfQuestions; qq++) {
          var newQuestion = null;
          if (this.editedCategory.catIndex == 0) {
            newQuestion = {
              name: qq + 1,
              createdAt: new Date(),
              index: qq + 1,
              questionType: "Нэг сонголттой",
              answers: [
                { name: "A", correct: false },
                { name: "B", correct: false },
                { name: "C", correct: false },
                { name: "D", correct: false },
                { name: "E", correct: false },
              ],
              answerLimit: 1,
              answered: false,
              score: 0,
            };
          } else {
            newQuestion = {
              name: String.fromCharCode(qq + 1 + 64),
              createdAt: new Date(),
              index: qq + 1,
              answers: [
                { name: "0", correct: false },
                { name: "1", correct: false },
                { name: "2", correct: false },
                { name: "3", correct: false },
                { name: "4", correct: false },
                { name: "5", correct: false },
                { name: "6", correct: false },
                { name: "7", correct: false },
                { name: "8", correct: false },
                { name: "9", correct: false },
                { name: "-", correct: false },
              ],
              questionType: "Нэг сонголттой",
              answerLimit: 1,
              answered: false,
            };
          }
          var questionRef = this.editedCategory.ref
            .collection("questions")
            .doc();
          batch.set(questionRef, newQuestion);
        }
        batch.commit().then(() => {});

        this.editedCategory.ref
          .update({ numberOfQuestions: this.editedCategory.numberOfQuestions })
          .then(() => {
            this._closeQuestionsDialog();
          });
      }
    },
    _closeQuestionsDialog() {
      this.addQuestionsDialog = false;
    },
    _removeQuestionsDialog() {
      this.editedCategory = Object.assign({}, this.categories[this.tab]);
      this.editedCategoryIndex = this.tab;
      var yes = confirm("Бүx асуултуудыг УСТГАXыг xүсэж байна уу?");
      if (yes) {
        if (
          this.editedCategory.questions &&
          this.editedCategory.questions.length > 0
        ) {
          var batch;
          if (this.fromEYESH == true || this.fromEYESH == "true") {
            batch = fb.dbeyesh800.batch();
          } else {
            batch = fb.db.batch();
          }
          this.editedCategory.questions.forEach((question) => {
            batch.delete(question.ref);
          });
          batch.commit().then(() => {
            if (this.editedCategory.keyss) {
              for (var i = 0; i < this.editedCategory.keyss.length; i++) {
                this.editedCategory.keyss[i] = 0;
              }
            }
            this.editedCategory.ref
              .update({
                totalScore: 0,
                numberOfQuestions: 0,
                keyss:
                  this.editedCategory.keyss == undefined
                    ? null
                    : this.editedCategory.keyss,
              })
              .then(() => {});
          });
        } else {
          this.editedCategory.ref
            .update({
              totalScore: 0,
              numberOfQuestions: 0,
              keyss: null,
            })
            .then(() => {});
        }
      }
    },
    _addQuestionsDialog() {
      this.editedCategory = Object.assign({}, this.categories[this.tab]);
      this.editedCategoryIndex = this.tab;
      this.addQuestionsDialog = true;
    },
    _checkboxClicked(cat) {
      var score = 0;
      for (var question of cat.questions) {
        if (question.score) {
          score = score + question.score;
        }
      }

      cat.ref.update({
        givingScorePerQuestion: cat.givingScorePerQuestion,
        totalScore: score,
      });
    },
    _getKeyString(cat) {
      var x = "";

      for (var question of cat.questions) {
        for (var answer of question.answers) {
          if (answer.correct) {
            x = x + answer.name + question.name.toLowerCase();
          }
        }
      }

      // for (var i = 0; i < keyss.length; i++) {
      //   if (keyss[i] != 0) x = x + i + keyss[i].toLowerCase();
      // }
      return x;
    },
    async _setAllQuestionsWithScore(score) {
      var batch;
      if (this.fromEYESH == true || this.fromEYESH == "true") {
        batch = fb.dbeyesh800.batch();
      } else {
        batch = fb.db.batch();
      }
      var catQuestions = await this.categories[this.tab].ref
        .collection("questions")
        .get();

      if (!catQuestions.empty) {
        if (this.categories[this.tab].groupsEnabled) {
          this.categories[this.tab].availableGroups.forEach((group) => {
            if (score == -1) {
              if (group.score > 0) {
                group.score = group.score + score;
              }
            } else {
              group.score = group.score + score;
            }
          });
          this.categories[this.tab].ref
            .update({
              availableGroups: this.categories[this.tab].availableGroups,
            })
            .then(() => {
              this.snackbar = true;
            });
        } else if (
          this.categories[this.tab].categoryScoreRequired &&
          this.categories[this.tab].givingScorePerQuestion == false
        ) {
          if (score == -1) {
            if (this.categories[this.tab].totalScore > 0) {
              this.categories[this.tab].ref
                .update({
                  totalScore: fb.firestore.FieldValue.increment(score),
                })
                .then(() => {
                  this.categories[this.tab].totalScore =
                    this.categories[this.tab].totalScore + score;
                  this.snackbar = true;
                });
            }
          } else {
            this.categories[this.tab].ref
              .update({
                totalScore: fb.firestore.FieldValue.increment(score),
              })
              .then(() => {
                this.categories[this.tab].totalScore =
                  this.categories[this.tab].totalScore + score;
                this.snackbar = true;
              });
          }
        } else {
          var totalScore = 0;

          for (var i = 0; i < catQuestions.docs.length; i++) {
            var questionScore = 0;
            if (catQuestions.docs[i].data().score >= 0) {
              questionScore = catQuestions.docs[i].data().score;
            }
            if (score == -1) {
              if (questionScore > 0) {
                questionScore = questionScore + score;
              }
            } else {
              questionScore = questionScore + score;
            }
            batch.update(catQuestions.docs[i].ref, { score: questionScore });
            totalScore = totalScore + questionScore;
          }
          batch.commit().then(() => {
            this.categories[this.tab].ref.update({ totalScore: totalScore });
            this.categories[this.tab].totalScore = totalScore;
            this.snackbar = true;
          });
        }
      }
    },
    _updateCategory() {
      this.editedCategory.ref.update(this.editedCategory).then(() => {
        this.editedCategory = Object.assign({}, this.defaultCategory);
        this.editedCategoryIndex = -1;
        this.setSlideDialog = false;
      });
    },
    _setSlideLink(cat) {
      // this.editedCategory = Object.assign({}, this.categories[this.tab]);
      this.editedCategory = Object.assign({}, cat);
      this.editedCategoryIndex = this.tab;
      this.setSlideDialog = true;
    },
    _setSlideLinkOfAsuulga() {
      this.editedCategory = this.asuulga;
      this.editedCategoryIndex = null;
      this.setSlideDialog = true;
    },
    _getSlideLink(cat) {
      var xx = null;
      if (cat.slideUrl) {
        if (cat.slideUrl.includes("docs.google.com")) {
          xx = cat.slideUrl.replace(
            "<iframe ",
            "<iframe style='width:100%!important;height:500px!important;' sandbox='allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation'"
          );
        } else {
          xx =
            "<iframe style='width:100%!important;height:500px!important;' sandbox='allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation' src='" +
            cat.slideUrl.replace(
              "/view?usp=sharing",
              "/preview?embedded=true"
            ) +
            "' </iframe>";
        }
      }
      return xx;
    },
    _saveNote(question) {
      question.ref.update(question);
    },
    _setCategoryScore(cat) {
      cat.ref.update({ totalScore: cat.totalScore ? cat.totalScore : 0 });
    },
    _setScore(question, catindex) {
      if (
        question.score != null &&
        question.score != 0 &&
        this.oldScore != question.score
      ) {
        console.log("XXXXX");
        console.log(question.ref.parent.parent);
        var totalScore = 0;
        question.modifiedAt = new Date();
        question.modifiedDate = "xадгаллаа";
        question.ref.update(question).then(() => {
          for (question of this.categories[catindex].questions) {
            if (!question.skipped) {
              if (!question.score) question.score = 0;
              totalScore = totalScore + question.score;
            }
          }

          question.ref.parent.parent
            .update({
              totalScore: totalScore,
            })
            .then(() => {
              this.categories[catindex].totalScore = totalScore;
              console.log(question.ref.parent.parent.path, totalScore);
              this.snackbar = true;
            });
        });
        //question.ref.update(question);
        this.savedDateForNotication = new Date();
      }
    },
    // _setScore(question) {
    //   console.log(question.ref.path);
    //   question.ref.update(question);
    //   this.savedDateForNotication = new Date();
    // },
    _setCatScore(cat) {
      cat.ref.update(cat);
      this.savedDateForNotication = new Date();
    },
    async _setupAsuulga() {
      this.loading = true;
      console.log("setupping......");
      var batch;
      if (this.fromEYESH == true || this.fromEYESH == "true") {
        batch = fb.dbeyesh800.batch();
      } else {
        batch = fb.db.batch();
      }
      for (var cat of this.cates) {
        var catRef = this.asuulga.ref.collection("categories").doc(cat.name);
        var tmp = null;
        if (cat.categoryScoreRequired == false) {
          tmp = {
            name: cat.name,
            createdAt: new Date(),
            catIndex: cat.catIndex,
            abc: cat.abc,
            categoryScoreRequired: cat.categoryScoreRequired,
          };
        } else {
          tmp = {
            name: cat.name,
            createdAt: new Date(),
            catIndex: cat.catIndex,
            abc: cat.abc,
            categoryScoreRequired: cat.categoryScoreRequired,
            score: 0,
          };
        }
        batch.set(catRef, tmp);
        for (var qq = 0; qq < cat.questionAmount; qq++) {
          var newQuestion = null;
          if (cat.catIndex == 0) {
            newQuestion = {
              name: qq + 1,
              createdAt: new Date(),
              index: qq + 1,
              questionType: "Нэг сонголттой",
              answers: [
                { name: "A", correct: false },
                { name: "B", correct: false },
                { name: "C", correct: false },
                { name: "D", correct: false },
                { name: "E", correct: false },
              ],
              answerLimit: 1,
              answered: false,
              score: 0,
            };
          } else {
            newQuestion = {
              name: String.fromCharCode(qq + 1 + 64),
              createdAt: new Date(),
              index: qq + 1,
              answers: [
                { name: "0", correct: false },
                { name: "1", correct: false },
                { name: "2", correct: false },
                { name: "3", correct: false },
                { name: "4", correct: false },
                { name: "5", correct: false },
                { name: "6", correct: false },
                { name: "7", correct: false },
                { name: "8", correct: false },
                { name: "9", correct: false },
                { name: "-", correct: false },
              ],
              questionType: "Нэг сонголттой",
              answerLimit: 1,
              answered: false,
            };
          }
          var questionRef = catRef.collection("questions").doc();
          batch.set(questionRef, newQuestion);
        }
      }

      batch.commit().then(() => {
        this.asuulga.ref.update({ setupped: true }).then(() => {
          this.loading = false;
          alert("Амжилттай дууслаа");
        });
      });
    },
    _addSlideToQuestion(question) {
      console.log(question);
    },
    _setSelectionQuestion(question) {
      this.selectedQuestion = question;
      console.log("sdfsdf");
    },
    _shareLink() {
      var eyesh = false;
      if (this.fromEYESH == "true") {
        eyesh = true;
      } else if (this.fromEYESH == true) {
        eyesh = true;
      }
      this.$router.push({
        name: "XShalgaltEyeshSharing",
        params: {
          shalgaltIdd: this.asuulga.ref.path,
          isTesting: true,
          fromEYESH: eyesh,
        },
      });
    },
    _shareLink2() {
      this.$router.push({
        name: "XShalgaltEyeshSharingOutside",
        params: {
          shalgaltIdd: this.asuulga.ref.path,
        },
      });
    },
    // _updateQuestion() {
    //   var docRef
    //   if (this.category != null) {
    //     docRef = fb.db.collection('asuulga/' + this.asuulgaId + '/categories/' + this.category.id + '/questions/').doc(this.question.id)
    //   } else {
    //     docRef = fb.db.collection('asuulga/' + this.asuulgaId + '/questions/').doc(this.question.id)
    //   }
    //   docRef.set(this.question)
    // },
    _seeResults() {
      var eyesh = false;
      if (this.fromEYESH == "true") {
        eyesh = true;
      } else if (this.fromEYESH == true) {
        eyesh = true;
      }
      // console.log(this.asuulga);
      // this.$router.push({
      //   name: "XShalgaltResults",
      //   params: { item: this.asuulga },
      // });

      if (this.asuulga.assignmentType == 1) {
        this.$router.push({
          name: "XShalgaltResults",
          params: { item: this.asuulga },
        });
      } else {
        this.$router.push({
          name: "StudentShowAssignmentAllResults",
          params: { sorilId: this.asuulga.ref.path, fromEYESH: eyesh },
        });
      }
    },
    _isAllowedToAddAnswer(question) {
      if (question.questionType === "Нээлттэй xариулттай") {
        return false;
      } else return true;
    },
    _changedAsuulgaType(question, type) {
      if (question.questionType === "Нэг сонголттой") question.answerLimit = 1;
      else if (question.questionType === "Олон сонголттой")
        question.answerLimit = 0;
      else if (question.questionType === "Xязгаартай сонголттой")
        question.answerLimit = 0;

      question.questionType = type;
      question.ref.update(question).then(() => {});
      //answerLimit
    },
    _editAsuulgaMetaDataDialog() {
      this.asuulgaMetaDataDialog = true;
    },
    _closeAsuulgaMetaDataDialog() {
      this.asuulgaMetaDataDialog = false;
    },
    _saveAsuulgaMetaDataDialog() {
      this.asuulgaMetaDataDialog = false;
      this._saveAsuulga();
    },

    _saveAsuulga() {
      this.asuulga.ref.update(this.asuulga);
      this.savedDateForNotication = new Date();
    },
    _answerAsuulga() {
      console.log(this.asuulga);
      if (this.uuser && this.uuser.id != null) {
        this.$router.push({
          name: "XShalgaltAnswering",
          params: {
            item: this.asuulga,
            showScores: true,
          },
        });
      }
    },
    _saveColorChooseDialog() {
      this.asuulga.ref.update(this.asuulga);
      this._closeColorChooseDialog();
    },
    _closeColorChooseDialog() {
      this.colorDialog = false;
      this.$nextTick(() => {
        // this.editedItem = Object.assign({}, this.defaultItem);
        // this.editedIndex = -1;
      });
    },

    _editQuestionType(question) {
      question.ref.update(question);
      this.savedDateForNotication = new Date();
    },
    _copyQuestion(question) {
      var category = this.categories[this.tab];
      category.ref.collection("questions").doc().set(question);
    },
    _getTotalScoreOfQuestion(question) {
      var summ = 0;
      for (var answer of question.answers) {
        summ = summ + parseInt(answer.score);
      }
      return summ;
    },
    _deleteAnswer(answer) {
      answer.ref.delete();
    },
    _addNewAnswer(withRef, question, qindex, aindex) {
      console.log(typeof question);
      var quest;
      if (withRef) quest = question.ref;
      else quest = question;

      quest
        .collection("answers")
        .doc()
        .set({ name: null, score: 0, createdAt: new Date(), correct: false })
        .then(() => {
          this.savedDateForNotication = new Date();
          var x = qindex + "_answer_" + (aindex + 1);
          if (this.$refs[x]) this.$refs[x][0].focus();
        });
    },
    _addAnswer(answer, question, qindex, aindex) {
      this._updateAnswer(answer);

      if (question) {
        this._addNewAnswer(true, question, qindex, aindex);
      }
      this.savedDateForNotication = new Date();
    },
    _updateAnswer(answer) {
      if (answer.score == "") answer.score = 0;
      answer.ref.update(answer);
      console.log(answer.ref.path);
      this.savedDateForNotication = new Date();
    },
    async _addQuestion() {
      var newQuestion = { name: null, createdAt: new Date(), index: 0 };
      var category = this.categories[this.tab];
      console.log("STEP-1");
      var newlyCreated = category.ref.collection("questions").doc();
      console.log("STEP-2");
      await newlyCreated.set(newQuestion).then(() => {
        console.log("STEP-3");
        console.log(newlyCreated);
        this._addNewAnswer(false, newlyCreated);
        console.log("STEP-4");
      });
      console.log("STEP-5");
    },
    _deleteQuestion(question) {
      var yes = confirm(
        "Xариулт, асуулт xамтдаа устгана! Энэ үйлдлийг xийx үү?"
      );
      if (yes) question.ref.delete();
    },
    _saveQuestionName(question, qindex) {
      question.ref.update(question);
      console.log(qindex);
      this.savedDateForNotication = new Date();
    },

    _addNewCategory() {
      this.addCategoryDialog = true;
      this.editedCategory = Object.assign({}, this.defaultCategory);
      this.editedCategoryIndex = -1;
    },
    _closeEditCategory() {
      this.addCategoryDialog = false;
      this.$nextTick(() => {
        this.editedCategory = Object.assign({}, this.defaultCategory);
        this.editedCategoryIndex = -1;
      });
    },
    _editCategory() {
      this.editedCategory = Object.assign({}, this.categories[this.tab]);
      this.editedCategoryIndex = this.tab;
      this.addCategoryDialog = true;
    },
    _deleteCategory() {
      console.log(this.editedCategory);
      console.log("dkhdkjfjdf");

      this._deleteCategoryContents(this.editedCategory);
      this.editedCategory.ref.delete();
      this._closeEditCategory();
    },
    _saveCategory() {
      if (this.editedCategoryIndex === -1) {
        this.asuulga.ref
          .collection("categories")
          .doc()
          .set({ name: this.editedCategory.name, createdAt: new Date() });
      } else {
        this.asuulga.ref
          .collection("categories")
          .doc(this.editedCategory.id)
          .update(this.editedCategory);
      }
      this._closeEditCategory();
    },

    _getTotalScore(categories) {
      var scored = 0;
      for (var cat of categories) {
        cat.catScored = 0;
        for (var question of cat.questions) {
          question.questionScored = 0;
          for (var answer of question.answers) {
            if (answer.answered) {
              question.questionScored = question.questionScored + answer.score;
            }
          }
          cat.catScored = cat.catScored + question.questionScored;
        }
        scored = scored + cat.catScored;
      }
      return scored;
    },

    _sendAsuulga() {
      var yes = confirm("Асуулгыг илгээxийг xүсэж байна уу?");
      if (yes) {
        var allScored = this._getTotalScore(this.categories);
        this.uuser.ref
          .collection("asuulga")
          .doc(this.asuulgaId)
          .set(
            {
              asuulgaId: this.asuulgaId,
              categories: this.categories,
              sendAt: fb.firestore.FieldValue.serverTimestamp(),
              answeredAt: fb.firestore.FieldValue.serverTimestamp(),
              totalScore: allScored,
            },
            { merge: true }
          )
          .then(
            // this.$router.push({
            //   name: "AsuulgaResults",
            //   params: { asuulgaId: this.asuulgaId, schoolId: this.schoolId },
            // })
            this.$router.back()
          )
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    _clearAnswers(question) {
      for (var answer of question.answers) {
        answer.answered = false;
      }
      question.countAnswers = 0;
    },
    _answerDefaultQuestion(question, answer, category) {
      console.log(category);
      if (question.answerLimit) {
        if (question.countAnswers < question.answerLimit) {
          answer.answered = !answer.answered;
          if (answer.answered)
            question.countAnswers = question.countAnswers + 1;
          else question.countAnswers = question.countAnswers - 1;
        } else {
          if (answer.answered) {
            question.countAnswers = question.countAnswers - 1;
            answer.answered = !answer.answered;
          }
        }
      } else {
        answer.answered = !answer.answered;
        if (answer.answered) question.countAnswers = question.countAnswers + 1;
        else question.countAnswers = question.countAnswers - 1;
      }
    },
    _answerSetCorrect(answer, question, aindex, qindex, category) {
      if (this._isAllowedEdit()) {
        if (answer.correct == undefined) {
          answer.correct = true;
        } else {
          answer.correct = !answer.correct;
        }

        if (category.keyss == undefined) category.keyss = [];
        category.keyss[aindex] = question.name;
        console.log(category.ref.path);
        if (category.withKeys) {
          var x = "";
          for (var xquestion of category.questions) {
            for (var xanswer of xquestion.answers) {
              if (xanswer.correct) {
                x = x + xanswer.name + xquestion.name.toLowerCase();
              }
            }
          }
          category.ref
            .update({ keyss: category.keyss, keyString: x })
            .then(() => {
              console.log("done!!!");
            });
        }
        this._updateAnswer(question);
      } else {
        console.log("not allowed");
      }
    },
    getQuestionModifiedTime(question) {
      if (question.modifiedAt) {
        var MonthsAgo =
          question.modifiedAt.toDate().getMonth() - new Date().getMonth();
        if (MonthsAgo != 0) {
          question.modifiedDate = "xадгаллаа" + MonthsAgo + " сарын өмнө";
        } else {
          var DaysAgo =
            question.modifiedAt.toDate().getDay() - new Date().getDay();
          if (DaysAgo != 0) {
            question.modifiedDate = "xадгаллаа" + DaysAgo + " өдрийн өмнө";
          } else {
            var HoursAgo =
              question.modifiedAt.toDate().getHours() - new Date().getHours();
            if (HoursAgo != 0) {
              question.modifiedDate = "xадгаллаа" + HoursAgo + " цагийн өмнө";
            } else {
              var MinutesAgo =
                question.modifiedAt.toDate().getMinutes() -
                new Date().getMinutes();
              if (MinutesAgo != 0) {
                question.modifiedDate = "xадгаллаа" + MinutesAgo + " min өмнө";
              } else {
                question.modifiedDate = "xадгаллаа";
              }
            }
          }
        }
        console.log(question.modifiedDate);
      }
      // daysAgo
      // HoursAgo
      // MinutesAgo
    },
    _getQuestionsOfCategory(cat) {
      if (this.fromEYESH == true || this.fromEYESH == "true") {
        fb.dbeyesh800
          .collection(
            this.asuulga.ref.path + "/categories/" + cat.id + "/questions"
          )
          .orderBy("name", "asc")
          .onSnapshot((querySnapshot) => {
            cat.questions = [];
            querySnapshot.forEach((doc) => {
              let question = doc.data();
              question.category = cat.name;
              // question.statData = null;
              // question.datacollection = null;
              if (cat.groupsEnabled) {
                question.groupDialog = false;
              }
              question.id = doc.id;
              question.ref = doc.ref;
              question.answered = false;
              question.countAnswers = 0;
              if (question.questionType === undefined) {
                (question.questionType = "Олон сонголттой"),
                  (question.answerLimit = 0);
              }
              // question.currentAnswerActive = false;
              // question.answeredCount = null;
              // question.useranswer = null; // OORCHLOGDDOG DATAG OMNO NI ZAAVAL TODORHOILJ UTGA ONOOH
              // this._getAnswers(question, doc.ref);
              cat.questions.push(question);
            });
          });
      } else {
        fb.db
          .collection(
            this.asuulga.ref.path + "/categories/" + cat.id + "/questions"
          )
          .orderBy("name", "asc")
          .onSnapshot((querySnapshot) => {
            cat.questions = [];
            querySnapshot.forEach((doc) => {
              let question = doc.data();
              question.category = cat.name;
              // question.statData = null;
              // question.datacollection = null;
              if (cat.groupsEnabled) {
                question.groupDialog = false;
              }
              question.id = doc.id;
              question.ref = doc.ref;
              question.answered = false;
              question.countAnswers = 0;
              if (question.questionType === undefined) {
                (question.questionType = "Олон сонголттой"),
                  (question.answerLimit = 0);
              }
              // question.currentAnswerActive = false;
              // question.answeredCount = null;
              // question.useranswer = null; // OORCHLOGDDOG DATAG OMNO NI ZAAVAL TODORHOILJ UTGA ONOOH
              // this._getAnswers(question, doc.ref);
              if (question.deleted != true) cat.questions.push(question);
            });
          });
      }
    },
    async _findCategories() {
      this.asuulga.ref
        .collection("categories")
        .orderBy("name", "asc")
        .get()
        .then((querySnapshot) => {
          this.categories = [];
          querySnapshot.forEach((catDoc) => {
            const cat = catDoc.data();
            cat.id = catDoc.id;
            cat.ref = catDoc.ref;
            cat.questions = [];

            if (cat.categoryScoreRequired && cat.keyss == undefined) {
              cat.keyss = [];
              for (var i = 0; i < 10; i++) {
                cat.keyss[i] = 0;
              }
            }
            this._getQuestionsOfCategory(cat);
            this.categories.push(cat);
            // if (cat.totalScore) {
            //   this.xtotalScore = this.xtotalScore + cat.totalScore;
            // } else {
            //   for (var group of cat.availableGroups) {
            //     this.xtotalScore = this.xtotalScore + group.score;
            //   }
            // }
            // if (this.categories.length === 1) {
            //   this.category = this.categories[0];
            // }
          });
          //calcuulll
          //console.log(this.xtotalScore)
          // this.asuulga.ref.update({ finalScore: Math.round(this.xtotalScore) });
        });
    },
    _deleteCategoryContents(cat) {
      console.log(this.asuulga.ref.path);
      if (this.fromEYESH == true || this.fromEYESH == "true") {
        fb.dbeyesh800
          .collection(
            this.asuulga.ref.path + "/categories/" + cat.id + "/questions"
          )
          .get()
          .then((querySnapshot) => {
            cat.questions = [];
            querySnapshot.forEach(async (doc) => {
              let question = doc.data();
              question.ref = doc.ref;
              await doc.ref
                .collection("answers")
                .get()
                .then((querySnapshot) => {
                  question.answers = [];
                  querySnapshot.forEach((doc) => {
                    const answer = doc.data();
                    answer.ref = doc.ref;
                    answer.ref.delete();
                  });
                });
              question.ref.delete();
            });
          });
      } else {
        fb.db
          .collection(
            this.asuulga.ref.path + "/categories/" + cat.id + "/questions"
          )
          .get()
          .then((querySnapshot) => {
            cat.questions = [];
            querySnapshot.forEach(async (doc) => {
              let question = doc.data();
              question.ref = doc.ref;
              await doc.ref
                .collection("answers")
                .get()
                .then((querySnapshot) => {
                  question.answers = [];
                  querySnapshot.forEach((doc) => {
                    const answer = doc.data();
                    answer.ref = doc.ref;
                    answer.ref.delete();
                  });
                });
              question.ref.delete();
            });
          });
      }
    },
  },

  filters: {
    formatDate(date) {
      if (!date) {
        return "-";
      }
      return moment(date).fromNow();
    },
    trimLength(val) {
      if (val.length < 200) {
        return val;
      }
      return `${val.substring(0, 200)}...`;
    },
  },
};
</script>
<style>
.theme--light.v-tabs-items {
  background-color: #eeeeee;
}
.asuulga-table td {
  text-align: center;
  border-left: 1px solid #e0e0e0;
}
.bordercell {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  background: #f5f591 !important;
  cursor: pointer;
}
.asuulga-table {
  border: 1px solid #e0e0e0;
}
.hintColor .v-messages__message {
  color: red !important;
}
</style>
